
import Dashboard from './Components/Dashboard/dashboard';
import Login from './Components/Auth/Login/login';
import './App.css'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from '../src/Components/lib/privateRoute'
function App() {

  return (
    <BrowserRouter >
      <Switch>
          <Route path="/" exact component={Login} />
          {/* <Route path="/dashboard" exact component={Dashboard} /> */}
          <PrivateRoute path="/dashboard" exact component={Dashboard} />
      </Switch>
    </BrowserRouter >
  );
}

export default (App);