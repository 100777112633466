
import React, { useState } from 'react'
import { AdminLogin } from "../../services/api-servics";
import { showToaster } from "../../services/helper";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
export default function Login() {
    const history = useHistory();
    const [modal, setModal] = useState(false);
    const [complantDetails, setComplantDetails] = useState(undefined)
    const [totalCOuntDetails, setTotalCountDetails] = useState(undefined)
    const [pageLoad, setPageLoad] = useState(false);

    const [mail, setMail] = useState(null);
    const [password, setPassword] = useState(null);


    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Email is required"),
            password: Yup.string().required("Password is required"),
        }),
        onSubmit: (values) => {


            try {
                let loginData = {
                    "email": values.email,
                    "password": values.password
                }
                setPageLoad(true)
                AdminLogin(loginData).then(async (response) => {
                    if (
                        response !== "" &&
                        response !== undefined &&
                        response !== null && response.data !== null && response.data !== undefined&&
                        response.data.status==true
                    ) {
                        console.log(response);
                        setComplantDetails(response.data)
                        localStorage.setItem("enol-xt", response.data.token);
                        localStorage.setItem("enol-uid", response.data.id);
                        setPageLoad(false)
                        showToaster(
                            "Sucessfully fetch details ",
                            "success"
                        );
                        history.push("/dashboard");
                    }
                    else {
                        setPageLoad(false)
                        showToaster(
                            response.data.message,
                            "warning"
                        );
                    }
                });

            } catch (e) {
                setPageLoad(false)
                console.log(e, "reeeeeeeee");
                showToaster(
                    "Something went wrong",
                    "warning"
                );
            }

        },
    });
    return (

        <>
            <div id="overlay"></div>
            <div class="loader"></div>
            {/* <form onSubmit={formik.handleSubmit}> */}
                <section class="sign_up">
                    <div class="s_img">
                        <img src="assets/images/login/login-banner.svg" alt="hero_img" style={{ width: "661px" }} />
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div class="s_body">
                            <div class="s_logo">
                                <img src="assets/images/chat/logo/logo.svg" alt="logo" width="150px" />
                            </div>
                            <div class="sign_in">
                                <h2>SIGN IN</h2>
                                <p>Sign In to get started.</p>
                            </div>

                            <div class="s_input">

                                <input
                                    onChange={(e) => {
                                        formik.handleChange(e);

                                    }}
                                    // onBlur={formik.handleBlur}
                                    value={mail}
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="User Name"
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="input-error-msg">
                                        {formik.errors.email}
                                    </div>
                                ) : null}
                                <input
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    // onBlur={formik.handleBlur}
                                    value={password}
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Password" />
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="input-error-msg">
                                        {formik.errors.password}
                                    </div>
                                ) : null}
                            </div>
                            <button type="submit" class="S_b">SIGN IN</button>

                        </div>
                    </form>

                </section>
            {/* </form> */}
        </>


    )
}
