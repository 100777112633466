

import React from 'react'
import { showToaster } from "../../services/helper";
import { useHistory } from "react-router-dom";
export default function Header() {
 
    const history = useHistory();
    const onLogoutHandler = async () => {
        // const shouldLogout = window.confirm("Are you sure you want to logout?");
        // if (shouldLogout) {
        try {    
            localStorage.removeItem("enol-xt");
            localStorage.removeItem("enol-uid");
            history.push("/");
        } catch (error) {
            showToaster("Session Expired", "warning");
        }
        // };
    }
    return (
        <section class="banner">
            <div class="container-fluid">
                <div class="container">
                    <div class="row">
                        <div class="top d-flex align-items-center justify-content-end">
                            <div class="col-md-4">
                                <div class="logo d-flex align-items-center justify-content-end">
                                    <img src="img/E-NOL.png" class="img-fluid" /></div>
                                <div class="logotag">Early Notification of Loss</div>
                            </div>
                            <div class="col-md-5">
                                <div class="navbar text-center">
                                    <div class="Home d-flex align-items-center justify-center">
                                        <div class="icon"><i class="fa-solid fa-house"></i></div>
                                        <a href="#">Home</a>
                                    </div>
                                    <div class="Home d-flex align-items-center justify-center">
                                        <div class="icon"><i class="fa-solid fa-house"></i></div>
                                        <a href="#">Fraud Analysis</a>
                                    </div>
                                    <div class="Home d-flex align-items-center justify-center">
                                        <div class="icon"><i class="fa-solid fa-house"></i></div>
                                        <a href="#">Risk Analytics</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="icons">
                                    <div class="icon1">
                                        <a href="#">
                                            <i class="fa-solid fa-bell"></i>
                                        </a>
                                    </div>
                                    <div class="icon1">
                                        <div class="dropdown">
                                            <a class="dropdown-toggle" href="#" id="Dropdown" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                                                <i class="flag-united-kingdom flag m-0"></i>
                                                English
                                            </a>
                                            <ul class="dropdown-menu" aria-labelledby="Dropdown">
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-united-kingdom flag"></i>English <i class="fa fa-check text-success ms-2"></i></a>
                                                </li>
                                                <li><hr class="dropdown-divider" /></li>
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-poland flag"></i>Polski</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-china flag"></i>中文</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-japan flag"></i>日本語</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-germany flag"></i>Deutsch</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-france flag"></i>Français</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-spain flag"></i>Español</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-russia flag"></i>Русский</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="flag-portugal flag"></i>Português</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="icon1">
                                        <div class="dropdown">
                                            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-mdb-toggle="dropdown" aria-expanded="false">
                                                Profile
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                <li><button class="dropdown-item" type="button">Edit</button></li>
                                                <li><button class="dropdown-item" type="button">Dashboard</button></li>
                                                <li><button class="dropdown-item" type="button" onClick={onLogoutHandler}>Logout</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="row">
                        <div class="col-md-12">
                            <div class="row name">
                                <h2>Good evening, Sid</h2>
                            </div>
                            <div class="row"></div>
                        </div>
                     
                    </div> */}
                </div>
            </div>
        </section>
    )
}
