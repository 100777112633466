
import React from 'react'
// import Loading from '../assets/img/loader.gif';
export default function pageLoader(props) {
    return (
        <>
        { props.showLoader?
        <div className="loader-wrapper video-loader">
            <div className="loader-inner">
             Loading...
                {/* <img src={Loading}></img> */}
            </div>
        </div>:""}
        </>
    )
}








